import {React, useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion";
import P1R from '../../assets/PHASE1-RED.png';
import P2R from '../../assets/PHASE2-RED.png';
import P3R from '../../assets/PHASE3-RED.png';
import P4R from '../../assets/PHASE4-RED.png';
import P5R from '../../assets/PHASE5-RED.png';

const Roadmap = () => {
  const [alredyAnimated, setAlredyAnimated] = useState();
  const {ref, inView} = useInView();
  const animationRight = useAnimation();
  const animationLeft = useAnimation();

  useEffect(() => {
    if(inView){
        animationRight.start({
          x:0,
          transition:{
            type: "spring", 
            stiffness: 25, 
            duration: 2 
          },
          initial:{
            x:'100vh'
          }
        })
        animationLeft.start({
          x:0,
          transition:{
            type: "spring", 
            stiffness: 25, 
            duration: 2 
          },
          initial:{
            x:'-100vh'
          }
        })
        setAlredyAnimated(true);
    }
    if(!inView&&!alredyAnimated){
      animationRight.start({
        x:'100vh',
      })
      animationLeft.start({
        x:'-100vh',
      })
    }
    if(!inView&&alredyAnimated){
      animationRight.start({
        x:0,
      })
      animationLeft.start({
        x:0,
      })
    }
  })
    return (
      <div className="flex flex-col items-center bg-black" id="roadmap">
        <div className="title-container-shadow text-full-white flex flex-col bg-dark-bg sticky z-10 top-14 font-title p-4 md:text-3xl w-full text-5xl">
            <h3 className="font-title justify-center mx-auto md:text-3xl text-5xl">Roadmap</h3>
        </div>
        <div ref={ref} className="header-bg w-full rounded-lg py-24 p-8 grid grid-cols-21 pt-36 grid-rows-5">
          <div className="row-span-full md:col-start-3 md:col-span-2 col-start-11 flex mx-auto flex-wrap">
            <div className="m-2 w-[8px] rounded-3xl h-full self-center bg-full-white" />
          </div>
          <img src={P1R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-1 mx-auto w-2/3"/>
          <img src={P2R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-2 mx-auto w-2/3"/>
          <img src={P3R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-3 mx-auto w-2/3"/>
          <img src={P4R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-4 mx-auto w-2/3"/>
          <img src={P5R} alt="this is car image" className="col-start-11 md:col-start-3 md:col-span-2 min-w-[2rem] row-start-5 mx-auto w-2/3"/>
          <motion.div animate={animationRight} className="col-span-8 md:col-start-5 md:col-span-12 col-start-12 row-start-1 p-4 text-left"> 
            <h3 className="text-full-red text-4xl md:text-2xl font-title">Preparing for the Riot</h3>
            <p className="text-full-white text-2xl md:text-lg font-text">Online community building <br/> Pre-sale chances for whitelist members<br/> WL and Giveaways to the most active members</p>
          </motion.div>
          <motion.div animate={animationLeft} className="col-span-8 md:col-start-5 md:col-span-12 col-start-3 p-4 row-start-2 md:text-left text-right"> 
            <h3 className="text-full-red text-4xl md:text-2xl font-title">The Rise of the Lucky Bunnies</h3>
            <p className="text-full-white text-2xl md:text-lg font-text">Guerilla warfare announcement Start Minting<br/>Creation of private channel on discord for holders only<br />Ramp-up Marketing initiatives<br/>Listing on rarity tools
            </p>
          </motion.div>
          <motion.div animate={animationRight} className="col-span-8 md:col-start-5 md:col-span-12 col-start-12 p-4 row-start-3 text-left"> 
            <h3 className="text-full-red text-4xl md:text-2xl font-title">The Institutional Greedy Owls are coming</h3>
            <p className="text-full-white text-2xl md:text-lg font-text">Greedy Owls minting<br/>Alpha Physical Game<br/>First comic stripes - graphic novel Release</p>
          </motion.div>
          <motion.div animate={animationLeft} className="col-span-8 md:col-start-5 md:col-span-12 col-start-3 p-4 row-start-4 md:text-left text-right"> 
            <h3 className="text-full-red text-4xl md:text-2xl font-title">The War Begins</h3>
            {/* <p className="text-full-white text-2xl md:text-lg font-text">DAO launch and Community wallet (royalties 5% divided in 1% funding team 2% community wallet 2% GOLB project and 1% to holders)<br/>Charity donations decided by the community<br/> Buy land on the metaverse to enhance project networking</p> */}
            <p className="text-full-white text-2xl md:text-lg font-text">DAO launch and Community wallet<br/>Charity donations decided by the community<br/> Buy land on the metaverse to enhance project networking</p>
            </motion.div>
          <motion.div animate={animationRight} className="col-span-8 md:col-start-5 md:col-span-12 col-start-12 p-4 row-start-5 text-left"> 
            <h3 className="text-full-red text-4xl md:text-2xl font-title">The New Crypto Era</h3>
            <p className="text-full-white text-2xl md:text-lg font-text">Alpha release Indie Game development with Utility Token<br/> Merch shops</p>
          </motion.div>
        </div>
      </div>
    );
  };
  
export default Roadmap;