import React from 'react';
import { AboutContent } from '../../components';
import owlandbunny from '../../assets/owlandbunny.png';
import owlandbunny2 from '../../assets/owlandbunny3.png';

const About = () => {
    return (
      <div className="flex flex-col items-center h-fit header-bg" id="about">
         <div className="title-container-shadow text-full-white flex flex-col bg-dark-bg sticky z-10 top-14 font-title p-4 md:text-3xl w-full text-5xl">
            <h3 className="font-title justify-center mx-auto md:text-3xl text-5xl">About</h3>
        </div>
        <div className="grow mt-16 flex lg:flex-col-reverse rounded-3xl shadow-lg lg:w-[75%] flex-row-reverse lg:text-center text-left xl:w-2/3 w-1/2 mx-auto my-auto h-fit red-earning-bg">
          
        </div>
        <AboutContent reverse={false} imgSrc={owlandbunny}  title="" text="Greedy Owls & Lucky Bunnies is a collection of a total of 10,000 randomly generated NFTs on the Ethereum blockchain..."/>
        <AboutContent reverse={true} imgSrc={owlandbunny2}  title="" text="...Our Owls are cruel, ruthless and grim… but the anarchist Bunnies, equipped with visionary weapons are brave and tenacious, they are real badass…" option={true}/>
        <div className="pb-4"></div>
      </div>
    );
  };
  
export default About;