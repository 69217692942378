import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

const MobileNavbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
    return (
      <div className="title-container-shadow hidden md:flex text-full-white flex-col bg-red-600 sticky z-40 top-0 font-title p-4 md:text-3xl w-full text-5xl">
          <div className="flex flex-row">
            <h3 className="font-title justify-center mx-auto md:text-3xl text-5xl"><a href="/#home">GOLB Club</a></h3>
            <div className="ml-auto flex-col mr-4 justify-end my-auto items-center">
              {toggleMenu
                ? <RiCloseLine color="#fff" className="text-3xl" size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color="#fff" className="text-3xl" size={27} onClick={() => setToggleMenu(true)} />}
            </div>
          </div>
          {toggleMenu && (
            <div className="flex lore-bg h-full w-full justify-center items-center flex-col sticky right-0 top-0 mt-4 mx-auto rounded-lg">
              <div>
                <p><a href="/#home" onClick={() => setToggleMenu(false)}>Home</a></p>
                <p><a href="/#lore" onClick={() => setToggleMenu(false)}>Lore</a></p>
                <p><a href="/#about" onClick={() => setToggleMenu(false)}>About</a></p>
                <p><a href="/#roadmap" onClick={() => setToggleMenu(false)}>Roadmap</a></p>
                <p><a href="/#team" onClick={() => setToggleMenu(false)}>Team</a></p>
                <p><a href="/legal" onClick={() => setToggleMenu(false)}>Legal</a></p>
              </div>
            </div>
            )}
        </div> 
    );
  };
  
  export default MobileNavbar;