import React from 'react';

const MintSoonButton = () => {

  return(
    <div className="flex justify-between xl:py-3 px-2 py-6 flex-wrap w-[100%]">
        <button type="button" className="w-full bg-full-red text-white rounded-lg p-3 font-title text-xl mint-soon-button"> Minting Soon... </button>
    </div>
  );
  
};

export default MintSoonButton;
