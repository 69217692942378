import React from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { motion } from "framer-motion";
import owlandbunny from '../../assets/GREEDY_OWL.png';
import bunny1 from '../../assets/LUCKY_BUNNY.png';
import { SocialNetworks, MintButton } from '..';
import MintSoonButton from '../mintSoonButton/MintSoonButton';

const HeaderContent = () => {
    return (
        <div className="grow w-full flex flex-row">
          <div className="max-w-2xl xl:max-w-xl md:max-w-md lg:max-w-lg flex flex-col justify-center align-middle mx-auto my-auto">
            <motion.h1 initial={{ y: '-100vh' }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}  className="title-text-shadow text-full-red text-huge md:text-8xl lg:text-9xl xl:text-9xl font-title">GOLB</motion.h1>
            <motion.h2 initial={{ x: '-100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}   className="title-text-shadow text-full-white text-8xl md:text-4xl lg:text-6xl xl:text-7xl font-title">Greedy Owls& Lucky Bunnies</motion.h2>
            <motion.p initial={{ x: '100vh' }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 65, duration: 2 }}    className="text-full-white text-2xl lg:text-lg md:text-lg xl:text-xl lg:p-4 p-2 font-text text-justify">Greedy Owls & Lucky Bunnies is a double collection of a total of 10,000 randomly generated NFTs on the Ethereum blockchain. Our Owls are cruel, ruthless and grim… but the anarchist Bunnies, equipped with visionary weapons are brave and tenacious, they are real badass…</motion.p>
            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}  transition={{ type: "spring", stiffness: 65, duration: 2 }}>
              {/* <MintButton /> */}
              <MintSoonButton/>
              <SocialNetworks />
            </motion.div>
            <a href="#lore" className="text-2xl w-[15%] z-0 shrink mx-auto mt-4 my-auto align-middle justify-center animate-bounce" ><BsArrowDown className="fill-white z-0 w-[100%] h-[100%]" /></a>
            <a href="#lore" className="text-full-white shrink text-xl">Explore <b className="font-title">GOLB</b></a>
          </div>
        </div>
    );
  };
  
  export default HeaderContent;