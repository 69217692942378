import React from 'react';
import { StoryContent } from '../../components';
import lore1 from '../../assets/greedyOwl1.png';
import lore2 from '../../assets/Joker.png';
import lore3 from '../../assets/Napalm.png';

const Lore = () => {
    return (
      <div className="flex flex-col lore-bg bg-no-repeat bg-right-bottom bg-cover pb-6 items-center align-middle justify-center min-h-screen mx-auto w-full" id="lore">
        <div className="title-container-shadow text-full-white flex flex-col bg-dark-bg sticky z-10 top-14 font-title p-4 md:text-3xl w-full text-5xl mb-10">
            <h3 className="font-title justify-center mx-auto md:text-3xl text-5xl">Lore</h3>
        </div>
        <StoryContent reverse={true} title="In 2008, a financial crisis" text="brought world economies to their knees, leading to the collapse of the financial system.
Those responsible for the crisis, greedy predators grouped in the brotherhood of bad owls, are a handful of white-collar workers representing 1% of the population but owning 90% of the world's wealth, who get richer and richer by controlling government and banking institutions, while blood flows on the streets." imgSrc={lore1} imgAlt={lore1}/>
        <StoryContent title="Millions of people lose their jobs" text="their homes, see a decrease in the purchasing power of savings accumulated over years of hard work.
In the aftermath of this crisis, a small anarchist group of lucky bunnies have embraced the cause of cryptocurrencies, a decentralised financial system designed to protect 99% of the population from the brotherhood of owls." imgSrc={lore2} imgAlt="Lore Bg 1"/>
        <StoryContent reverse={true} title="In the last decade," text="the war between the two factions has intensified, while the brotherhood of owls tries to sabotage the rise of crypto and commits all its resources to avoid the spread of a decentralised system. Yet, the anarchist bunnies are armed to their teeth to protect the revolution and will not give up easily…." imgSrc={lore3} imgAlt="Lore Bg 1" />
      </div>
    );
  };
  
export default Lore;