import React from 'react';
import { LegalContent } from '../../components';

const Legal = () => {
    return (
      <div className="flex flex-col lore-bg bg-no-repeat bg-right-bottom bg-cover pb-6 items-center align-middle justify-center min-h-screen mx-auto w-full" id="lore">
        <h3 className="h3-title text-full-white bg-light-bg sticky z-10 top-14 font-title p-4 w-full text-7xl mb-10">Legal Terms</h3>
        <LegalContent title="1. Thank you for being part of the GOLB Club Community!" text="Greedy Owls and Lucky Bunnies (GOLB Club) is an NFT trait-generative project powered by the Ethereum blockchain and started by two friends who are crypto enthusiastic at heart. We genuinely appreciate your support of our project and are humbled that you are here. It’s unfortunate that these terms of service (“TOS”) are necessary, but we want to be transparent and we include this TOS before our launch. Our intention is to make this project open and transparent, and to foster the creative spirit that we hope that you will bring to the GOLB Club. We are creators and NFT enthusiasts just like you are. Hop on the Discord, contact one of our team members, and we will work to address any questions or concerns you might have." />
        <LegalContent title="2. What We Own" text="All rights that are not specifically granted to the users and owners of GOLB NFTs are reserved by the Team. This includes but is not limited to the intellectual property rights surrounding the “Greedy Owls and Lucky Bunnies” and “GOLB” names, logos, trademarks, the website, the look and feel of the user interface, the smart contract code, the generative traits in general, and any future community wallet. In addition to owning the IP surrounding the name “Greedy Owls and Lucky Bunnies” and “GOLB”, we reserve the exclusive right to use GOLB Club official logos for future merchandising purposes." />
        <LegalContent title="3. You Own Your GOLB NFT" text="The initial product launch of GOLB is a run of 10,000 individual ERC-721a tokens (“Bunnies”, “Owls” or “NFTs”) with generative traits that correspond to pieces of art hosted on IPFS. By connecting your ethereum wallet and minting a Bunny with our smart contract, you gain full and complete ownership of your NFT." />
        <LegalContent title="4. You Own Exclusive Commercial Rights to Your NFT" text="You will own full commercial rights to your Bunny or Owl so long as you continue to own and control the NFT. You may assign some of these rights to other commercial projects (e.g. Punks Comics, Boring Coffee Company), but any future transfer of your NFT will be subject to those rights that you have already assigned. You can put your Bunny or Owl on clothing and merchandise, include them in video games and video projects, and generally do whatever you want with your Bunny. You cannot use the name “Greedy Owls and Lucky Bunnies” on any commercial product, or use “GOLB” unless connected directly to a “#” and the number of your Bunny or Owl signifiying the NFT you own. You agree not to use your Bunny or Owl in any project or derivative work that involves hate speech, racism, pornography, or any other illegal content." />
        <LegalContent title="5. Many Third Party Uses Are Not Restricted" text="Nothing in these terms are meant to limit you or a third party from (i) owning or operating an NFT marketplace that permits the use or sale of the GOLB Club NFT, so long as the marketplace cryptographically verifies the ownership of the NFT to ensure that only the owner can use and display their NFT; and (ii) owning or operating a third party website or application that permits the inclusion or involvement of the Bunny or Owl generally, provided that the website or application verifies the ownership of the NFT to ensure that only the owner can use and display their NFT. Furthermore, nothing in this TOS is meant to restrict a third party website’s ability to build tools which track traits or sales." />
        <LegalContent title="6. Limitations of Liability for Gas, Failed Transactions, Smart Contract Bugs" text="We have worked very hard to make sure that there are no bugs in our smart contract and that our initial NFT launch is smooth. As we have seen in many previous projects, however, sometimes things break. You agree to hold The Company harmless for any losses you may incur as a consequence of minting your NFT. These potential losses include any gas fees for failed transactions, any excessive gas fees charged due to website or smart contract bugs, and any loss of your NFT due to website or smart contract bugs." />
        <LegalContent title="7. No Guarantees or Future Promises" text="GOLB Club hopes to continue to develop this project and community. We have released a roadmap outlining some of the future goals we hope to accomplish. While we intend to work towards those goals, sometimes things happen and we cannot guarantee that this original project will sell out entirely nor can we guarantee future developments. You agree that your purchase of a Owl or Bunny from our initial project is all you are guaranteed to receive with your initial purchase. Any future potential airdrops, community gatherings, or other benefits are ancillary to this purchase and not to be taken into consideration with your initial purchase. You agree that you are not relying on any future commitments by GOLB Club in using this site and participating in our NFT launch." />
        <LegalContent title="8. Bunnies and Owls Are Not Intended as Investments GOLB Club" text="NFTs are meant to be a fun avatar and Non-Fungible Token for you to collect. They are not meant as investment vehicles. We make absolutely no promise or guarantee that these NFTs will be worth anything. You understand that they have no inherent monetary value, and they should be treated as nothing more than a fun and beautiful collectible." />
        <LegalContent title="9. Taxes" text="You are entirely responsible for any tax liability which may arise from minting or reselling your NFT." />
        <LegalContent title="10. Class Action Waiver" text="You agree to waive any class action status, and any legal dispute around the GOLB Club project which you may choose to bring can only be done on an individual basis." />
        <LegalContent title="11. Children" text="The GOLB Club project is not targeted towards children. You agree that you are over the age of 18, or above the legal age of your jurisdiction, whichever is greater." />
        <LegalContent title="12. Jurisdiction and Choice of Law" text="You agree that for purposes of any legal dispute, you will be subject to the jurisdiction of the United Kingdom and that any legal proceeding will be brought in the United Kingdom." />
        <LegalContent title="13. Privacy Policy" text="GOLB is not currently collecting any cookies, IP addresses, or user data in connection with your use of the website and app. You understand, however, that the Ethereum blockchain network is a public blockchain, and all of your transaction history initiated through the website will be made public. You also understand that GOLB may work with third-party apps, such as Discord or Collab.Land, which collect and store user data. In the future, GOLB Club will likely launch products (e.g. merch store) which require us to collect and store user data. In that event, this provision is subject to change." />
        <LegalContent title="14. Thank you!" text="The Greedy Owls and Lucky Bunnies Community will succeed only with the support of you and the other members of our community. We appreciate you, and sincerely thank you for being a part of our launch. See you on Discord and happy minting!" />
      </div>
    );
  };
  
export default Legal;