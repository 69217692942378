import React from 'react';
import { HeaderContent } from '../../components';

const Header = () => {
    return (
      <div className="bg-black flex flex-col items-center bg-golb-bg lg:bg-none bg-fixed bg-cover bg-no-repeat bg-center h-screen" id="home">
        <HeaderContent />
      </div>
    );
  };
  
export default Header;