import {React, useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion";
import bunny1 from '../../assets/bunny1.png';
import bunny2 from '../../assets/bunny2.png';
import bunny3 from '../../assets/3a.png';
import bunny4 from '../../assets/tarantino.png';
import bunny5 from '../../assets/23.png';
import bunny6 from '../../assets/54.png';
import bunny7 from '../../assets/151.png';
import bunny8 from '../../assets/1.png';
import owl1 from '../../assets/greedyOwl1.png';
import owl2 from '../../assets/65.png';
import owl3 from '../../assets/3.png';
import owl4 from '../../assets/40.png';

const AboutContent = ({title, text, reverse, imgSrc, option}) => {
    const [alredyAnimated, setAlredyAnimated] = useState();
    const {ref, inView} = useInView();
    const animationRight = useAnimation();
    const animationLeft = useAnimation();
    const scaleUp = useAnimation();

    const renderRowPics = (num) => {
      if(num) {
        return (
          <div className="lore-bg-2 w-full grid gap-4 md:grid-cols-3 grid-cols-6 grid-rows-1 h-fit self-center p-4">
            <img src={owl1} alt="a Greedy Owl" className="lg:w-full bg-light-bg lg:p-0 mr-0 md:rounded-xl rounded-3xl"/>
            <img src={bunny3} alt="a Lucky Bunny" className="lg:p-0 bg-light-bg md:rounded-xl rounded-3xl"/>
            <img src={owl4} alt="a Greedy Owl" className="lg:p-0 bg-light-bg md:rounded-xl rounded-3xl"/>
            <img src={bunny8} alt="a Lucky Bunny" className="lg:p-0 bg-light-bg md:rounded-xl rounded-3xl"/>
            <img src={bunny6} alt="a Lucky Bunny" className="lg:p-0 bg-light-bg md:rounded-xl rounded-3xl"/>
            <img src={bunny1} alt="a Lucky Bunny" className="rounded-3xl md:rounded-xl bg-purple-bg"/>
          </div>
        )
      } else {
        return (
          <div className="lore-bg-2 w-full grid gap-4 md:grid-cols-3 grid-cols-6 grid-rows-1 h-fit self-center p-4">
          <img src={owl2} alt="a Greedy Owl" className="lg:w-full bg-light-bg lg:p-0 mr-0 md:rounded-xl rounded-3xl"/>
          <img src={bunny2} alt="a Lucky Bunny" className="lg:p-0 bg-light-bg md:rounded-xl rounded-3xl"/>
          <img src={bunny4} alt="a Lucky Bunny" className="lg:p-0 bg-light-bg md:rounded-xl rounded-3xl"/>
          <img src={bunny7} alt="a Lucky Bunny" className="lg:p-0 bg-light-bg md:rounded-xl rounded-3xl"/>
          <img src={owl3} alt="a Greedy Owl" className="lg:p-0 bg-light-bg md:rounded-xl rounded-3xl"/>
          <img src={bunny5} alt="a Lucky Bunny" className="rounded-3xl md:rounded-xl bg-purple-bg"/>
        </div>
        )
      }

    }

    useEffect(() => {
      if(inView){
          scaleUp.start({
            scale:1,
            transition:{
              type: "spring", 
              stiffness: 25, 
              duration: 2 
            }
          })
          animationRight.start({
            x:0,
            transition:{
              type: "spring", 
              stiffness: 25, 
              duration: 2 
            },
            initial:{
              x:'100vh'
            }
          })
          animationLeft.start({
            x:0,
            transition:{
              type: "spring", 
              stiffness: 25, 
              duration: 2 
            },
            initial:{
              x:'-100vh'
            }
          })
          setAlredyAnimated(true);
      }
      if(!inView&&!alredyAnimated){
        animationRight.start({
          x:'100vh',
        })
        animationLeft.start({
          x:'-100vh',
        })
        scaleUp.start({
          scale:0,
        })
      }
      if(!inView&&alredyAnimated){
        animationRight.start({
          x:0,
        })
        animationLeft.start({
          x:0,
        })
        scaleUp.start({
          scale:1,
        })
      }
    })
    return (
      <div ref={ref} className={reverse ? "bg-transparent mt-4 flex flex-col" : "bg-transparent flex flex-col mt-4"}>
        <motion.div animate={scaleUp} className={reverse ? "grow flex-col rounded-3xl shadow-lg lg:w-[75%] lg:text-center text-left xl:w-2/3 w-1/2 mx-auto my-auto h-fit red-earning-bg":"grow flex-col rounded-3xl shadow-lg lg:w-[75%] lg:text-center text-left xl:w-2/3 w-1/2 mx-auto my-auto h-fit red-earning-bg"}>
          <div className={reverse ? "flex flex-row lg:flex-col-reverse":"flex flex-row-reverse lg:flex-col-reverse"}>
            <motion.div animate={reverse ? animationRight : animationLeft} className="lg:max-w-[95%] h-fit lg:p-0 p-8 self-center">
              <p className="text-full-white lg:text-2xl md:text-lg p-2 font-text text-2xl">{text}</p>
            </motion.div>
            <motion.img animate={reverse ? animationLeft : animationRight} src={imgSrc} alt="this is car image" className="w-1/2 max-w-240 max-h-240 md:max-w-160 md:max-h-160 md:h-1/3 md:p-0 m-4 self-center"/>
          </div>
          {renderRowPics(option)}
        </motion.div>
      </div>
      
    );
  };
  
  export default AboutContent;