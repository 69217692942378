import './App.css';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import LegalPage from "./pages/LegalPage";
import NoPage from "./pages/NoPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, {useEffect, useState} from 'react';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-3CL0KXV1R2";
ReactGA.initialize(TRACKING_ID);

const App = () => {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.addEventListener('load', setLoaded(true))
  }, []);

  return (
    <div id='app-container' className=''>
      <div className={`loader-wrapper ${loaded ? 'loader-wrapper-off' : ''}`}>
        <span class="loader">
          <span class="loader-inner"></span>
        </span>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="legal" element={<LegalPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
