import React, { useState } from 'react';

const Navbar = ({sticky}) => {
    return (
      <section id="navbar-container navbar-sticky" className={sticky ? "navbar-div navbar-sticky" : "navbar-div sticky top-0 z-20"}>
          <nav className="bg-dark-bg border-gray-200 dark:bg-gray-800 md:hidden">
            <ul className="flex flex-row mt-0 text-sm font-semibold mx-auto w-full h-full">
              <li className="basis-1/6 text-white py-[12.5px] bg-red-600 hover:bg-red-700 font-title h-full w-full lg:text-sm text-xl text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <a href="/#home" type="button" className="h-full w-full">Home</a>
              </li>
              <li className="basis-1/6 text-white py-[12.5px] bg-red-800 hover:bg-red-700 font-title h-full w-full lg:text-sm text-xl text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <a href="/#lore" type="button" className="h-full w-full">Lore</a>                
              </li>
              <li className="basis-1/6 text-white py-[12.5px] bg-red-800 hover:bg-red-700 font-title h-full w-full lg:text-sm text-xl text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <a href="/#about" type="button" className="h-full w-full">About</a>                 
                </li>
              <li className="basis-1/6 text-white py-[12.5px] bg-red-800 hover:bg-red-700 font-title h-full w-full lg:text-sm text-xl text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <a href="/#roadmap" type="button" className="h-full w-full">Roadmap</a>
              </li>
              <li className="basis-1/6 text-white py-[12.5px] bg-red-800 hover:bg-red-700 font-title h-full w-full lg:text-sm text-xl text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <a href="/#team" type="button" className="h-full w-full">Team</a>                
              </li>
              <li className="basis-1/6 text-white py-[12.5px] bg-red-800 hover:bg-red-700 font-title h-full w-full lg:text-sm text-xl text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <a href="/legal" type="button" className="h-full w-full">Legal</a>               
              </li>
            </ul>
          </nav>
        </section>  
    );
  };
  
  export default Navbar;