import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from '../assets/owlandbunny.png';

class NotFoundPage extends React.Component{
    render(){
        return <div className='Not-Found'>
            <img src={PageNotFound} alt='Not Found' />
            <p style={{textAlign:"center"}}>
              <Link to="/">Go to Home </Link>
            </p>
          </div>;
    }
}
export default NotFoundPage;