import React from 'react';
import { FaTwitter, FaGithub, FaDiscord, FaMediumM, FaInstagram } from 'react-icons/fa';

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const SocialNetworks = () => (
  <div className="flex justify-around xl:py-3 px-2 py-6 flex-wrap w-[100%]">
    <div>
      <button type="button" className="bg-transparent"> <FaTwitter onClick={()=>openInNewTab('https://twitter.com/GOLB_club')} className="text-5xl xl:text-4xl lg:text-3xl fill-full-white hover:fill-full-red" /> </button>
    </div>
    <div>
      <button type="button" className="bg-transparent"> <FaInstagram onClick={()=>openInNewTab('https://www.instagram.com/golb_club')} className="text-5xl xl:text-4xl lg:text-3xl fill-full-white hover:fill-full-red" /> </button>
    </div>
    <div>
      <button type="button" className="bg-transparent"> <FaDiscord onClick={()=>openInNewTab('https://discord.gg/VXJJPrjJs9')} className="text-5xl xl:text-4xl lg:text-3xl fill-full-white hover:fill-full-red" /> </button>
    </div>
    {/*<div>
       <button type="button" className="bg-transparent"> <FaMediumM className="text-5xl xl:text-4xl lg:text-3xl  fill-full-white hover:fill-full-red" /> </button>
    </div>*/}
  </div>
);

export default SocialNetworks;
