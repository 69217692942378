import { Outlet, Link } from "react-router-dom";
import { Header, Lore, About, Roadmap, Team, Faq } from '../containers';
import { Navbar, Footer } from '../components';

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  )
};

export default Layout;