import {React, useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion";
import { AboutContent } from '../../components';
import owl1 from '../../assets/32.png';
import ghost from '../../assets/ghost.png';
import bunny3 from '../../assets/bunnymurales.png';
import bunny4 from '../../assets/bunnyminer.png';
import owl2 from '../../assets/70.png';
import kethan from '../../assets/Kethan.png';
import TeamMember from '../../components/teamMember/TeamMember';

const Team = () => {
  const [alredyAnimated, setAlredyAnimated] = useState();
  const {ref, inView} = useInView();
  const animationRight = useAnimation();
  const animationLeft = useAnimation();

  useEffect(() => {
    if(inView){
        animationRight.start({
          x:0,
          transition:{
            type: "spring", 
            stiffness: 25, 
            duration: 2 
          },
          initial:{
            x:'100vh'
          }
        })
        animationLeft.start({
          x:0,
          transition:{
            type: "spring", 
            stiffness: 25, 
            duration: 2 
          },
          initial:{
            x:'-100vh'
          }
        })
        setAlredyAnimated(true);
    }
    if(!inView&&!alredyAnimated){
      animationRight.start({
        x:'100vh',
      })
      animationLeft.start({
        x:'-100vh',
      })
    }
    if(!inView&&alredyAnimated){
      animationRight.start({
        x:0,
      })
      animationLeft.start({
        x:0,
      })
    }
  })
    return (
      <div className="flex flex-col items-center h-fit bg-light-bg" id="team">
        <div className="title-container-shadow text-full-white flex flex-col bg-dark-bg sticky z-10 top-14 font-title p-4 md:text-3xl w-full text-5xl mb-10">
            <h3 className="font-title justify-center mx-auto md:text-3xl text-5xl">Team</h3>
        </div>
        <div className="lg:w-[75%] grid grid-cols-3 grid-rows-2 md:grid-cols-1 md:grid-rows-4 lg:grid-cols-2 lg:grid-rows-2 xl:w-2/3 w-1/2 py-12 my-8 mb-4 rounded-lg max-h-1/3">
          <TeamMember imgSrc={owl2} name="Alfredo" role="Co-founder & Dev" />
          <TeamMember imgSrc={bunny3} name="Tyler" role="Co-founder" />
          <TeamMember imgSrc={bunny4} name="Miguel" role="Blockchain Dev" />
          <TeamMember imgSrc={owl1} name="Lazar" role="Designer" />
          <TeamMember imgSrc={ghost} name="Ghost350" role="Social Media" />
          <TeamMember imgSrc={kethan} name="Kethan" role="Social Media" />
        </div>
      </div>
    );
  };
  
export default Team;