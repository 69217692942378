import React from 'react';

const FAQS = [
  {
    q: 'What is the total supply?',
    a: `We are creating a collection of 5,000 Greedy Owls and 5,000 Lucky Bunny for a total of 10,000 unique randomly generated NFT as part of the Golb.Club project.`
  },
  {
    q: 'Which Blockchain are the NFT deployed on?',
    a: 'All the NFTs of the Golb.Club project will be deployed on the Ethereum Blockchain using a ERC-721A Contract.'
  },
  {
    q: 'What are the mint price and the max amount?',
    a: '0.1Ξ for the public sale. Max (5) mints per wallet for public sale.'
  },
  {
    q: 'Where can you buy a Golb.Club NFT?',
    a: 'If you miss the initial mint, All the NFT can be purchased on OpenSea'
  },  
  {
    q: 'Are there secondary sale royalties?',
    a: 'Yes there are 5% secondary sales royalties. Will be split between the founding team and the community wallet, to work on the project long term goals'
  },

]

const renderFaq = ({q='', a=''}, idx = 0) => <div className="accordion-item bg-dark-bg border border-red-200">
  <h2 className="accordion-header font-title mb-0" id={`heading${idx}`}><button className="accordion-button relative flex items-center first-letter:w-full w-full py-4 px-5 text-xl text-full-white text-left bg-dark-bg border-0 rounded-none transition focus:outline-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${idx}`} aria-expanded="false" aria-controls={`collapse${idx}`}>{q}</button></h2>
  <div
    id={`collapse${idx}`}
    className="accordion-collapse collapse"
    aria-labelledby={`heading${idx}`}
    data-bs-parent="#accordionExample"
  >
    <div className="accordion-body font-text text-full-white text-lg py-4 px-5">
        {a}
    </div>
  </div>
</div>


const Faq = () => {
    return (
      <div className="flex flex-col items-center h-fit bg-light-bg" id="about">
        <div className="title-container-shadow text-full-white flex flex-col bg-dark-bg sticky z-10 top-14 font-title p-4 md:text-3xl w-full text-5xl mb-10">
            <h3 className="font-title justify-center mx-auto md:text-3xl text-5xl">FaQ</h3>
        </div>
        <div className="accordion lg:w-[75%] xl:w-2/3 w-1/2 py-14" id="accordionExample">
          {FAQS.map((x,idx)=>renderFaq(x,idx))}
        </div>
      </div>
    );
  };
  
export default Faq;