import {React, useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion";

const TeamMember = ({name, role, imgSrc}) => {
    const [alredyAnimated, setAlredyAnimated] = useState();
    const {ref, inView} = useInView();
    const scaleUp = useAnimation();

    useEffect(() => {
      if(inView){
          scaleUp.start({
            scale:1,
            transition:{
              type: "spring", 
              stiffness: 25, 
              duration: 2 
            }
          })
          setAlredyAnimated(true);
      }
      if(!inView&&!alredyAnimated){
        scaleUp.start({
          scale:0,
        })
      }
      if(!inView&&alredyAnimated){
        scaleUp.start({
          scale:1,
        })
      }
    })
    return (
     <motion.div ref={ref} animate={scaleUp} className="flex justify-center min-w-[25%] m-2">
        <div className="rounded-lg shadow-lg bg-dark-bg max-w-sm">
          <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
            <img className="rounded-t-lg team-img" src={imgSrc} alt=""/>
          </a>
          <div className="p-4">
            <a className="text-4xl cursor-pointer hover:text-full-white font-title text-full-red">{name}</a>
            <p className="text-full-white text-xl font-text">{role}</p>
          </div>
        </div>
      </motion.div>
      
    );
  };
  
  export default TeamMember;