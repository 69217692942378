import { Header, Lore, About, Roadmap, Team, Faq } from '../containers';
import { Navbar, Footer, MobileNavbar } from '../components';

const Home = () => {
    return(
    <div className="w-screen text-center select-none">
        <Header />
        <Navbar className="sticky top-0"/>
        <MobileNavbar />
        <Lore />
        <About />
        <Roadmap />
        <Team />
        <Faq />
        <Footer />
    </div>
    )};
  
  export default Home;