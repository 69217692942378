import { Legal } from '../containers';
import { Navbar, Footer, MobileNavbar } from '../components';

const LegalPage = () => {
    return(
    <div className="text-center select-none"> 
        <Navbar className="sticky top-0"/>
        <MobileNavbar />
        <Legal />
        <Footer />
    </div>
    )};
  
  export default LegalPage;