import {React, useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion";

const StoryContent = ({title, text, reverse, imgSrc}) => {
    const [alredyAnimated, setAlredyAnimated] = useState();
    const {ref, inView} = useInView();
    const animationRight = useAnimation();
    const animationLeft = useAnimation();

    useEffect(() => {
      if(inView){
          animationRight.start({
            x:0,
            transition:{
              type: "spring", 
              stiffness: 25, 
              duration: 2 
            },
            initial:{
              x:'100vh'
            }
          })
          animationLeft.start({
            x:0,
            transition:{
              type: "spring", 
              stiffness: 25, 
              duration: 2 
            },
            initial:{
              x:'-100vh'
            }
          })
          setAlredyAnimated(true);
      }
      if(!inView&&!alredyAnimated){
        animationRight.start({
          x:'100vh',
        })
        animationLeft.start({
          x:'-100vh',
        })
      }
      if(!inView&&alredyAnimated){
        animationRight.start({
          x:0,
        })
        animationLeft.start({
          x:0,
        })
      }
    })

    return (
        <div ref={ref} className={reverse ? "grow flex lg:flex-col-reverse flex-row-reverse lg:text-center text-left lg:w-[75%] xl:w-2/3 w-1/2 mx-auto my-auto pt-6 h-fit" : "grow lg:text-center lg:flex-col-reverse flex mx-auto my-auto pt-6 flex-row text-right lg:w-[75%] xl:w-2/3 w-1/2 h-fit"}>
          <motion.div animate={reverse ? animationRight : animationLeft}  className="lg:max-w-[95%] h-fit my-auto lg:p-0 p-8 align-bottom self-end">
            <h4 className={reverse ? "lg:text-4xl md:text-2xl text-full-red px-2 font-title text-4xl" : "lg:text-4xl text-full-red px-2 font-title text-4xl"}>{title}</h4>
            <p className="text-full-white md:text-lg lg:text-2xl font-text text-2xl">{text}</p>
          </motion.div>
          <motion.img animate={reverse ? animationLeft : animationRight}  src={imgSrc} alt="this is car image" className="w-1/4Screen lg:p-0 p-0 self-center rounded-3xl"/>
        </div>
      
    );
  };
  
  export default StoryContent;